import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    
    <div style={{ maxWidth: `100px`, marginBottom: `1.45rem` }}>
      <a href="/"><StaticImage
        src="../images/logo.png"
        alt="logo"
      /></a>
    </div>
    <h1 class="text-8xl font-bold my-24 xs:text-6xl">Launching 2022.</h1>
  </Layout>
)

export default IndexPage
